//import $ from 'jquery';
//global.jQuery = global.$ = $;

//import owlCarousel from './plugins/owl.carousel.min.js';
import 'owl.carousel';
import 'magnific-popup';

import customScript from './custom';


window.addEventListener('DOMContentLoaded', () => {
	"use strict";


	customScript();
});