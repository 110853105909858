/* ------------------------------------------**
* Fixed header
*--------------------------------------------*/
function headerFix() {
	const siteHeader = document.querySelector('.site-header');
	const scrollDistance = 150;

	if( window.scrollY > scrollDistance ) {
		siteHeader.classList.add('active');
	} else {
		siteHeader.classList.remove('active');
	}

	window.addEventListener('scroll', (e) => {
		if( window.scrollY > scrollDistance ) {
			siteHeader.classList.add('active');
			document.body.classList.add('site-scroll');
		} else {
			siteHeader.classList.remove('active');
			document.body.classList.remove('site-scroll');
		}
	})
}

/* ------------------------------------------**
* Top notice
*--------------------------------------------*/
function topNotice() {
	const topNotice = document.querySelector('.top-notice');
	const topNoticeClose = document.querySelector('.top-notice--close');

	topNotice.classList.remove('hide');

	topNoticeClose.addEventListener('click', (e) => {
		e.preventDefault();

		topNotice.classList.add('animation-hide');

		setTimeout(() => {
			topNotice.remove();
			localStorage.setItem('topNotice', 1);
		}, 320);
	});


	const isNotice = localStorage.getItem('topNotice');

	if(isNotice !== null) {
		topNotice.remove();
	}
}
/* ------------------------------------------**
* Scroll to anhor
*--------------------------------------------*/
function scrollToAnchor() {
	const triggerAnchor = document.querySelectorAll('.main-nav a');

	triggerAnchor.forEach(item => {
		item.addEventListener('click', (e) => {
			e.preventDefault();

			const href = item.getAttribute("href");
			const offsetTop = document.querySelector(href).offsetTop;

			scroll({
				top: offsetTop,
				behavior: "smooth"
			});
		});
	});
}

/* ------------------------------------------**
* Mobile navigation
*--------------------------------------------*/
function mobToggleNav() {
	$('.js-toggle-nav').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$('body').toggleClass('show-nav');

		$('#main-nav').slideToggle(300);
	});

	if( $(window).innerWidth() <= 990 ) {
		$('.nav--item--has-child').click(function() {
			$(this).find('> .sub-menu').slideToggle(300);
		});
	}

	if( $(window).innerWidth() <= 768 ) {
		$('.content-area.page-content table').addClass('table table-striped table-bordered').wrap('<div class="table-responsive"></div>');
	}
}

/* ------------------------------------------**
* Magnific popup
*--------------------------------------------*/
function magnific() {
	$('.js-popup').magnificPopup({
		closeOnBgClick: true,
		showCloseBtn:false,
		removalDelay: 500,
		midClick: true,
		callbacks: {
			beforeOpen: function() {
				this.st.mainClass = 'mfp-zoom-in';
			}
		},
	});

	$('.js-image-popup').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true
		}
	});

	$('.js-popup-gallery').each(function(index, el) {
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			tLoading: 'Загрузка #%curr%...',
			mainClass: 'mfp-img-mobile',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0,1],
				tCounter: '<span class="mfp-counter">%curr% из %total%</span>',
			},
		});
	});

	$('.js-popup-video').magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-fade',
		//removalDelay: 500,
		preloader: false,
		fixedContentPos: false,
		idClick: true,
		callbacks: {
			beforeOpen: function() {
				this.st.mainClass = this.st.el.attr('data-effect');
			},
			open: function() {
				$('.mfp-close').after('<span class="custom-mfp-close"><svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="26" height="2.8" rx="1.4" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 20.3647 18.7695)" fill="#2056A2"/><rect y="18.3848" width="26" height="2.8" rx="1.4" transform="rotate(-45 0 18.3848)" fill="#2056A2"/></svg></span>');
			},
		},
		iframe: {
			markup: '<div class="mfp-iframe-scaler">'+
						'<div class="mfp-close"></div>'+
						'<iframe class="mfp-iframe" frameborder="0" allow="autoplay" allowfullscreen></iframe>'+
					'</div>',
			patterns: {
				youtube: {
					index: 'youtube.com',
					id: 'v=',
					src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1'
				}
			},
		},
	});

	$(document).on('click', '.popup-modal-dismiss', function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});
}

/* ------------------------------------------**
* Text colapse
*--------------------------------------------*/
function textColspan() {
	const triggerColspan = document.querySelectorAll('.js-show-text');
	const contentMaxHeightDefault = '50px';

	triggerColspan.forEach(trigger => {
		trigger.addEventListener('click', e => {
			e.preventDefault();

			trigger.classList.toggle('active');

			const colspanContent = document.querySelector(trigger.getAttribute('href'));
			colspanContent.classList.toggle('active');

			if( colspanContent.classList.contains('active') ) {
				colspanContent.style.maxHeight = colspanContent.scrollHeight + 'px';
			} else {
				colspanContent.style.maxHeight = contentMaxHeightDefault;
			}
		});
	});
}

/* ------------------------------------------**
* Analog jQuery after
*--------------------------------------------*/
function insertAfter(newNode, referenceNode) {
	referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

/* ------------------------------------------**
* Response functions
*--------------------------------------------*/
function responsive() {
	$('#main-nav > .nav > .nav--item.has-children > a').click(function(e) {
		e.preventDefault();
	});

	if( screen.width <= 990 ) {
		$('#main-nav > .nav > .nav--item.has-children > a').click(function(e) {

			if( !$(this).parent().hasClass('down') ) {
				$('#main-nav > .nav > .nav--item.has-children').removeClass('down');
				$('#main-nav > .nav > .nav--item.has-children .sub-menu').slideUp(300);
			}

			$(this).parent().toggleClass('down');
			$(this).parent().find('.sub-menu').slideToggle(300);
		});

		$('#footer-nav > .nav--item.has-children > a').click(function(e) {
			e.preventDefault();

			const that = $(this);

			if( !$(this).parent().hasClass('down') ) {
				$('#footer-nav > .nav--item.has-children').removeClass('down');
				$('#footer-nav > .nav--item.has-children .sub-menu').slideUp(300);
			}

			setTimeout(function() {
				that.parent().toggleClass('down');
				that.parent().find('.sub-menu').slideToggle(300);
			}, 300);
		});

		$('[data-sm-remove-class]').each(function() {
			const remClass = $(this).attr('data-sm-remove-class');

			$(this).removeClass(remClass);
		});

		$('[data-sm-add-class]').each(function() {
			const addClass = $(this).attr('data-sm-add-class');

			$(this).addClass(addClass);
		});
	}

	$('.content-area ul li').wrapInner('<span></span>');
	$('.content-area ol li').wrapInner('<span></span>');
	$('.content-area table td').wrapInner('<span></span>');

	$('blockquote').wrapInner('<div></div>');
}

/* ------------------------------------------**
* OWL carousel
*--------------------------------------------*/
function owlCarousel() {
	if( screen.width <= 990 ) {
		$('#slider-testimonials > .item.last').remove();
	}

	setTimeout(function() {
		$('#slider-testimonials').owlCarousel({
			loop:false,
			margin:23,
			nav:false,
			dots: true,
			smartSpeed: 350,
			responsive:{
				0:{
					items:1
				},
				600:{
					items:2
				},
				1000:{
					items:3
				}
			},
		});
	}, 300);

	const carRelatedArt = $('#carousel-related-articles');

	carRelatedArt.owlCarousel({
		loop:false,
		margin:45,
		nav:false,
		dots: true,
		smartSpeed: 350,
		responsive:{
			0:{
				items:1,
				margin:15,
			},
			600:{
				items:2,
				margin:25,
			},
			1000:{
				items:2
			}
		},
	});
}

/* ------------------------------------------**
* Video
*--------------------------------------------*/
function playVideo() {
	const btnsVideoPlay = document.querySelectorAll('.js-play-video');

	if(btnsVideoPlay.length) {
		btnsVideoPlay.forEach(item => {
			item.addEventListener('click', e => {
				e.preventDefault();

				const idVideo = item.getAttribute('data-id-video');

				if( idVideo ) {
					const elIframe = document.createElement('iframe');
					elIframe.classList.add('iframe-video');

					elIframe.setAttribute('frameborder', 0);
					elIframe.setAttribute('allow', 'autoplay');
					elIframe.setAttribute('allowfullscreen', 'allowfullscreen');
					elIframe.setAttribute('src', 'https://www.youtube.com/embed/'+ idVideo +'?rel=0&amp;autoplay=1');

					item.parentElement.appendChild(elIframe);

					setTimeout(() => {
						item.parentElement.classList.add('show-iframe');
					}, 500);
				}
			});
		});
	}
}

/* ------------------------------------------**
* Init
*--------------------------------------------*/

function customScript() {
	try {
		playVideo();
	} catch(err) {
		console.log(err);
	}

	try {
		mobToggleNav();
	} catch(err) {
		console.log(err);
	}

	try {
		scrollToAnchor();
	} catch(err) {
		console.log(err);
	}

	try {
		owlCarousel();
	} catch(err) {
		console.log(err);
	}

	try {
		magnific();
	} catch(err) {
		console.log(err);
	}

	try {
		headerFix();
	} catch(err) {
		console.log(err);
	}

	try {
		topNotice();
	} catch(err) {
		console.log(err);
	}

	try {
		responsive();
	} catch(err) {
		console.log(err);
	}
}


export default customScript;